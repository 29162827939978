import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "../UserContext";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";

const Send = () => {
  const { token } = useUser();
  const navigate = useNavigate();
  const [sendInvoices, setSendInvoices] = useState([]);
  const [lightningInvoice, setLightningInvoice] = useState("");

  useEffect(() => {
    fetchSendInvoices();
  }, []);

  const fetchSendInvoices = async () => {
    try {
      const response = await axios.get("https://api.xbit.network/me/send", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      setSendInvoices(response.data);
    } catch (error) {
      console.error("Error fetching send invoices:", error);
    }
  };

  const handleSend = async () => {
    try {
      const response = await axios.post(
        "https://api.xbit.network/invoices/create",
        {
          paymentRequest: lightningInvoice,
          type: "send-external",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log(response.data);
      fetchSendInvoices(); // Refresh the list after creating a new invoice
      setLightningInvoice(""); // Reset the input
      navigate(`/send/${response.data.id}`, {
        state: { invoice: response.data },
      });
    } catch (error) {
      console.error("Error sending payment:", error);
      window.alert(
        `Error creating invoice: ${error.response.data.error}`,
      );
    }
  };

  const formatNumber = (num) => {
    if (num >= 100000000) {
      return (num / 100000000).toFixed(3) + " BTC";
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(3) + " M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(3) + " k";
    } else {
      return num.toFixed(0) + "";
    }
  };

  let totalAmount = 0;
  if (Array.isArray(sendInvoices) && sendInvoices.length > 0) {
    totalAmount = sendInvoices.reduce(
      (sum, invoice) =>
        sum + (invoice.status === "paid" ? invoice.amount : 0),
      0,
    );
  }

  return (
    <div>
      <button
        onClick={() => navigate(-1)}
        className="flex items-center text-blue-500 mb-6"
      >
        <ArrowLeft size={20} className="mr-2" />
        Back
      </button>
      {/* First segment: Total send invoices and amount */}
      <div className="mb-6">
        <div className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-lg p-2">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h2 className="text-2xl mb-2">{formatNumber(totalAmount)}</h2>
              <p>Value, SATS</p>
            </div>
            <div>
              <h2 className="text-2xl mb-2">
                {Array.isArray(sendInvoices) && sendInvoices.length > 0
                  ? sendInvoices.length
                  : 0}
              </h2>
              <p>No. of Invoices</p>
            </div>
          </div>
        </div>
      </div>

      {/* Second segment: Send payment form */}
      <div className="mb-6">
        <div className="flex flex-col">
          <textarea
            value={lightningInvoice}
            onChange={(e) => setLightningInvoice(e.target.value)}
            className="flex-grow mb-2 p-2 rounded-lg border border-gray-300 h-24 resize-none"
            placeholder="Enter Lightning Invoice"
          />
          <button
            onClick={handleSend}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          >
            SEND
          </button>
        </div>
      </div>

      {/* Third segment: List of send invoices */}
      <div className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-lg p-4 mt-6">
        <h3 className="font-bold mb-4">Invoices</h3>
        {sendInvoices.length > 0
          ? (
            Array.isArray(sendInvoices)
              ? (
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="text-left">Status</th>
                      <th className="text-right">Amount</th>
                      <th className="text-right">Fee</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sendInvoices
                      .map((invoice) => (
                        <tr key={invoice.id} className="border-t">
                          <td className="py-2">
                            {invoice.status.charAt(0).toUpperCase() +
                              invoice.status.slice(1)}
                          </td>
                          <td className="text-right py-2">
                            {formatNumber(invoice.amount)}
                          </td>
                          <td className="text-right py-2">
                            {formatNumber(invoice.fee)}
                          </td>
                          <td className="text-right py-2">
                            <Link
                              to={`/send/${invoice.id}`}
                              state={{ invoice }}
                              className="text-blue-500 hover:text-blue-700"
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )
              : null
          )
          : <p className="text-center text-gray-500">No invoices to be paid</p>}
      </div>
    </div>
  );
};

export default Send;
