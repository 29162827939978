import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ArrowLeft } from 'lucide-react';

const TweetDetail = () => {
  const { tweetId } = useParams();
  const navigate = useNavigate();
  const [tweet, setTweet] = useState(null);
  const [holdings, setHoldings] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('Holdings');

  useEffect(() => {
    fetchTweet();
  }, []);

  const fetchTweet = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `https://api.xbit.network/tweets/${tweetId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log(response.data);
      setTweet(response.data.asset);
      setHoldings(response.data.holdings);
      setOrders(response.data.orders);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch tweet details');
      setLoading(false);
    }
  };

  const handleBuild = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `https://api.xbit.network/tweets/${tweetId}/build`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log(response.data);
      window.alert('Tweet built successfully');
      fetchTweet();
    } catch (err) {
      console.error('Failed to build tweet', err);
      window.alert(`Unable to build tweet. Error: ${err.response.data.error}`);
    }
  };

  const handleNuke = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `https://api.xbit.network/tweets/${tweetId}/nuke`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log(response.data);
      window.alert('Tweet nuked successfully');
      fetchTweet();
    } catch (err) {
      console.error('Failed to nuke tweet', err);
      window.alert(`Unable to nuke tweet. Error: ${err.response.data.error}`);
    }
  };

  const formatNumber = (num) => {
    if (num === undefined) return '0';
    const parsedNum = typeof num === 'string' ? parseFloat(num) : num;
    return parsedNum.toLocaleString('en-US');
  };

  const formatPrice = (supply, realSats) => {
    if (supply === 0) return '0';
    const price = (realSats * 1_000_000) / supply;
    return price.toLocaleString('en-US', { maximumFractionDigits: 2 });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="min-h-screen p-2">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center text-blue-500 mb-6"
      >
        <ArrowLeft size={20} className="mr-2" />
        Back
      </button>

      {tweet && (
        <>
          <div className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-lg p-4">
            {tweet.brightId && (
              <>
                <div className="flex items-center mb-2">
                  <img
                    src={tweet.brightProfileImageLink}
                    alt={tweet.brightName}
                    className="w-10 h-10 rounded-full mr-3"
                  />
                  <div>
                    <h3 className="font-bold">{tweet.brightName}</h3>
                    <p className="text-sm text-gray-500">
                      @{tweet.brightUserPosted}
                    </p>
                  </div>
                </div>
                <p className="mb-2 break-all">{tweet.brightDescription}</p>
              </>
            )}
            <div className="grid grid-cols-2 gap-4 mb-0">
              <div>
                <p>Price, microSATS</p>
                <h2 className="text-2xl mb-2">
                  {formatPrice(tweet.supply, tweet.realSats)}
                </h2>
              </div>
              <div>
                <p>Supply</p>
                <h2 className="text-2xl mb-2">
                  {formatNumber(tweet.supply)}
                </h2>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 divide-x mb-4">
            <button
              className="bg-green-500 text-white py-2 text-center rounded-bl-lg"
              onClick={() => handleBuild()}
            >
              Build
            </button>
            <button
              onClick={() => handleNuke()}
              className="bg-red-500 text-white py-2 text-center rounded-br-lg"
            >
              Nuke
            </button>
          </div>

          {holdings && holdings.length > 0 ? (
            <>
              <div>
                <div className="flex mb-4">
                  <button
                    className={`mr-4 ${
                      activeTab === 'Holdings' ? 'font-bold' : ''
                    }`}
                    onClick={() => setActiveTab('Holdings')}
                  >
                    Holdings
                  </button>
                  <button
                    className={activeTab === 'Orders' ? 'font-bold' : ''}
                    onClick={() => setActiveTab('Orders')}
                  >
                    Orders
                  </button>
                </div>
                {activeTab === 'Holdings' && (
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="text-left">Username</th>
                        <th className="text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {holdings.map((holding) => (
                        <tr key={holding.id} className="border-t">
                          <td className="py-2">
                            @{holding.user.twitterUsername}
                          </td>
                          <td className="text-right py-2">{holding.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {activeTab === 'Orders' && (
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="text-left">Username</th>
                        <th className="text-left">Action</th>
                        <th className="text-right">Coin</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((order) => (
                        <tr key={order.id} className="border-t">
                          <td className="py-2">
                            @{order.user.twitterUsername}
                          </td>
                          <td className="text-right py-2">
                            {order.flow === 'buy' ? 'Build' : 'Nuke'}
                          </td>
                          <td className="text-right py-2">
                            {order.coinAmount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </>
          ) : (
            <div className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-lg p-4">
              <p>No holdings or orders available for this tweet.</p>
            </div>
          )}
        </>
      )}

      {tweet === null && (
        <div className="flex flex-col items-center justify-center h-screen">
          <div className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-lg p-8 text-center">
            <h2 className="text-2xl font-bold mb-4">Tweet Not Found</h2>
            <p className="mb-4">
              The tweet you're looking for doesn't exist or couldn't be loaded.
            </p>
            <button
              onClick={() => window.history.back()}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Go Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TweetDetail;