import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../UserContext';

const AuthCallback = () => {
  const { token, login } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const handleAffliateRegisteration = async () => {
    const promoterTwitterUsername = localStorage.getItem('promoterTwitterUsername');
    const token = localStorage.getItem('token');
    const response = await axios.post('https://api.xbit.network/affliates', 
      { promoterTwitterUsername },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    console.log(response.data);
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    if (token) {
      login(token);
      handleAffliateRegisteration();
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  }, [location, navigate]);

  return <div>Authenticating...</div>;
};

export default AuthCallback;