import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "../UserContext";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";

const Receive = () => {
  const { token } = useUser();
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState([]);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    try {
      const response = await axios.get("https://api.xbit.network/me/receive", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      setInvoices(response.data);
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  const handleReceive = async () => {
    try {
      const pendingInvoices = invoices.filter((invoice) =>
        invoice.status === "pending"
      );
      if (pendingInvoices.length > 2) {
        window.alert(
          "You have three pending invoices. Please complete them before creating a new one.",
        );
      } else {
        const response = await axios.post(
          "https://api.xbit.network/invoices/create",
          {
            amount,
            type: "receive-external",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        console.log(response.data);
        navigate(`/receive/${response.data.id}`, { state: { invoice: response.data } });        
      }
    
    } catch (error) {
      console.error("Error creating invoice:", error);
      window.alert(
          `Error creating invoice: ${error.response.data.error}`,
      );      
    }
  };

  const formatNumber = (num) => {
    if (num >= 100000000) {
      return (num / 100000000).toFixed(3) + " BTC";
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(3) + "";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(3) + "";
    } else {
      return num.toFixed(0) + "";
    }
  };

  let totalAmount = 0;
  if (Array.isArray(invoices) && invoices.length > 0) {
    totalAmount = invoices.reduce(
      (sum, invoice) => sum + (invoice.status === "confirmed" ? invoice.amount : 0),
      0,
    );
  }

  return (
    <div>
      <button
        onClick={() => navigate(-1)}
        className="flex items-center text-blue-500 mb-6"
      >
        <ArrowLeft size={20} className="mr-2" />
        Back
      </button>
      {/* First segment: Total invoices and amount */}
      <div className="mb-6">
        <div className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-lg p-2">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h2 className="text-2xl mb-2">{formatNumber(totalAmount)}</h2>
              <p>Value, SATS</p>
            </div>
            <div>
              <h2 className="text-2xl mb-2">
                {Array.isArray(invoices) ? invoices.length : 0}
              </h2>
              <p>No.of Invoices</p>
            </div>
          </div>
        </div>
      </div>

      {/* Second segment: Create invoice form */}
      <div className="mb-6">
        <div className="flex">
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(Number(e.target.value))}
            className="flex-grow mr-2 p-2 rounded-lg border border-gray-300"
            placeholder="Amount in SATS"
          />
          <button
            onClick={handleReceive}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          >
            RECEIVE
          </button>
        </div>
      </div>

      {/* Third segment: table of invoices */}
      <div className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-lg p-4 mt-6">
        <h3 className="font-bold mb-4">Invoices</h3>
        {invoices.length > 0
          ? (
            Array.isArray(invoices)
              ? (
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="text-left">Status</th>
                      <th className="text-right">Amount</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices
                      .map((invoice) => (
                        <tr key={invoice.id} className="border-t">
                          <td className="py-2">
                            {invoice.status.charAt(0).toUpperCase() +
                              invoice.status.slice(1)}
                          </td>
                          <td className="text-right py-2">{invoice.amount}</td>
                          <td className="text-right py-2">
                            <Link
                              to={`/receive/${invoice.id}`}
                              state={{ invoice }}
                              className="text-blue-500 hover:text-blue-700"
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )
              : null
          )
          : <p className="text-center text-gray-500">No SATS to receive yet</p>}
      </div>
    </div>
  );
};

export default Receive;
