import React from 'react';
import { Link } from 'react-router-dom';

const Orders = () => {
  return (
    <div>
      <h1 className="text-4xl font-bold mb-4">Orders</h1>
      <p>Here's a list of your orders:</p>
      <ul className="list-disc pl-5 mt-4">
        <li><Link to="/orders/1" className="text-blue-500 hover:text-blue-700">Order #1</Link></li>
        <li><Link to="/orders/2" className="text-blue-500 hover:text-blue-700">Order #2</Link></li>
        <li><Link to="/orders/3" className="text-blue-500 hover:text-blue-700">Order #3</Link></li>
      </ul>
    </div>
  );
};

export default Orders;