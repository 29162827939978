import React from 'react';
import { useParams } from 'react-router-dom';

const AssetDetail = () => {
  const { assetId } = useParams();

  return (
    <div>
      <h1 className="text-4xl font-bold mb-4">Asset Detail</h1>
      <p>Viewing details for asset ID: {assetId}</p>
    </div>
  );
};

export default AssetDetail;