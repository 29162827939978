import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import { UserProvider, useUser } from './UserContext';
import { ThemeProvider } from "./components/theme-provider"
import { Button } from "./components/ui/button"
import { ModeToggle } from "./components/mode-toggle"
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Marketplace from './pages/Marketplace';
import SwapPlace from './pages/SwapPlace';
import Assets from './pages/Assets';
import AssetDetail from './pages/AssetDetail';
import Orders from './pages/Orders';
import OrderDetail from './pages/OrderDetail';
import Vaults from './pages/Vaults';
import VaultDetail from './pages/VaultDetail';
import Airdrops from './pages/Airdrops';
import Profile from './pages/Profile';
import AirdropDetail from './pages/AirdropDetail';
import TweetDetail from './pages/TweetDetail';
import UserDetail from './pages/UserDetail';
import AuthCallback from './pages/AuthCallback';
import Send from './pages/Send';
import Receive from './pages/Receive';
import SendDetails from './pages/SendDetails';
import ReceiveDetails from './pages/ReceiveDetails';
import { Home as HomeIcon, Coins, Wallet, Gift, User, Store, ArrowLeftRight } from 'lucide-react'; // Add this import

const App = () => {

  const { token, isAuthenticated } = useUser();

  useEffect(() => {
    const shouldReload = Math.random() < 0.3; // 30% chance to reload
    const lastReloadTime = localStorage.getItem('lastReloadTime');
    const currentTime = new Date().getTime();
    const oneHour = 60 * 60 * 1000; // milliseconds in an hour

    if (shouldReload && (!lastReloadTime || currentTime - lastReloadTime > oneHour)) {
      localStorage.setItem('lastReloadTime', currentTime);
      window.location.reload();
    }
  }, []);

  const authenticateUser = () => {
    const token = localStorage.getItem('token');
    if(token) { return true; } else { return false; }
  };

  const PrivateRoute = ({ children }) => {
    return authenticateUser() ? (
      <>
        <main className="container mx-auto px-4 pt-2 pb-8 mb-16">
          {children}
        </main>
        <nav className="fixed bottom-0 left-0 right-0 bg-background border-t z-10">
          <div className="container mx-auto px-6 py-4">
            <ul className="flex justify-between items-center">
              <li><Button variant="ghost" asChild size="lg"><Link to="/dashboard"><HomeIcon size={28} /></Link></Button></li>
              <li><Button variant="ghost" asChild size="lg"><Link to="/assets"><Coins size={28} /></Link></Button></li>
              {/* <li><Button variant="ghost" asChild size="lg"><Link to="/vaults"><Wallet size={28} /></Link></Button></li> */}
              <li><Button variant="ghost" asChild size="lg"><Link to="/airdrops"><Gift size={28} /></Link></Button></li>
              <li><Button variant="ghost" asChild size="lg"><Link to="/profile"><User size={28} /></Link></Button></li>
            </ul>
          </div>
        </nav>
      </>
    ) : <Navigate to="/login" />;
  };
  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <UserProvider>
      <Router>
        <div className="min-h-screen bg-background text-foreground">
          <Routes>
            <Route path="/" element={authenticateUser() ? <Navigate to="/dashboard" /> : <Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/auth" element={<AuthCallback />} />
            <Route path="/users/:username" element={<UserDetail />} />
            
            {/* Wrap authenticated routes with PrivateRoute */}
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/marketplace" element={<PrivateRoute><Marketplace /></PrivateRoute>} />
            <Route path="/swapplace" element={<PrivateRoute><SwapPlace /></PrivateRoute>} />
            <Route path="/assets" element={<PrivateRoute><Assets /></PrivateRoute>} />
            <Route path="/assets/:assetId" element={<PrivateRoute><AssetDetail /></PrivateRoute>} />
            <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>} />
            <Route path="/orders/:orderId" element={<PrivateRoute><OrderDetail /></PrivateRoute>} />
            <Route path="/vaults" element={<PrivateRoute><Vaults /></PrivateRoute>} />
            <Route path="/vaults/:vaultId" element={<PrivateRoute><VaultDetail /></PrivateRoute>} />
            <Route path="/airdrops" element={<PrivateRoute><Airdrops /></PrivateRoute>} />
            <Route path="/airdrops/:airdropId" element={<PrivateRoute><AirdropDetail /></PrivateRoute>} />
            <Route path="/tweets/:tweetId" element={<PrivateRoute><TweetDetail /></PrivateRoute>} />
            <Route path="/send" element={<PrivateRoute><Send /></PrivateRoute>} />
            <Route path="/send/:invoiceId" element={<PrivateRoute><SendDetails /></PrivateRoute>} />
            <Route path="/receive" element={<PrivateRoute><Receive /></PrivateRoute>} />
            <Route path="/receive/:invoiceId" element={<PrivateRoute><ReceiveDetails /></PrivateRoute>} />
            {/* <Route path="/users/:username" element={<PrivateRoute><UserDetail /></PrivateRoute>} /> */}
          </Routes>
        </div>
      </Router>
      </UserProvider>
    </ThemeProvider>
  );
};

export default App;
