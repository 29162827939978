import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from '../UserContext';
import { useNavigate } from "react-router-dom";

const Airdrops = () => {
  const { token } = useUser();
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [popBalance, setPopBalance] = useState(null);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("Task");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      fetchAirdrops();
    }
  }, [token, navigate]);

  const fetchAirdrops = async () => {
    try {
      const response = await axios.get(
        "https://api.xbit.network/me/airdrops",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      setTasks(response.data.tasks);
      setRewards(response.data.rewards);
      setPopBalance(response.data.popBalance);
    } catch (err) {
      setError("Failed to fetch airdrops");
      console.error(err);
    }
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="min-h-screen p-2">
      {/* First segment */}
      <div className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-lg p-2 mb-6">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h2 className="text-2xl mb-2">
              {popBalance
                ? popBalance.amount.toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })
                : "0"}
            </h2>
            <p>POP</p>
          </div>
        </div>
      </div>

      {/* Second segment */}
      <>
        <div className="flex mb-4">
          <button
            className={`mr-4 ${activeTab === "Task" ? "font-bold" : ""}`}
            onClick={() => setActiveTab("Task")}
          >
            Task
          </button>
          <button
            className={activeTab === "Reward" ? "font-bold" : ""}
            onClick={() => setActiveTab("Reward")}
          >
            Reward
          </button>
        </div>
        {activeTab === "Task" && (
          <ul className="space-y-4">
            {tasks.length > 0 ? (
              tasks.map((task) => (
                <li
                  key={task.id}
                  className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-lg p-4"
                >
                  <p className="font-semibold">{task.title}</p>
                  <p className="text-sm mt-2">{task.description}</p>
                  <div className="flex justify-between mt-2">
                    <span>Reward: {task.reward} POP</span>
                    <span>Status: {task.status}</span>
                  </div>
                </li>
              ))
            ) : (
              <li className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-lg p-4">
                Please wait for more Airdrop tasks
              </li>
            )}
          </ul>
        )}

        {activeTab === "Reward" && (
          <ul className="space-y-4">
            {rewards.length > 0 ? (
              rewards.map((reward) => (
                <li
                  key={reward.id}
                  className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-lg p-4"
                >
                  <p className="font-semibold">
                    {reward.type.charAt(0).toUpperCase() + reward.type.slice(1)}
                  </p>
                  <div className="flex justify-between mt-2">
                    <span>
                      {reward.amount.toLocaleString("en-US", {
                        maximumFractionDigits: 0,
                      })}{" "}
                      POP
                    </span>
                    <span>
                      {new Date(reward.createdAt).toLocaleDateString()}
                    </span>
                  </div>
                </li>
              ))
            ) : (
              <li className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-lg p-4">
                Please be more active in promoting xBit to get rewards
              </li>
            )}
          </ul>
        )}
      </>
    </div>
  );
};

export default Airdrops;
