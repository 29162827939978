import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft, Copy } from "lucide-react";

const SendDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const invoice = location.state?.invoice;

  return (
    <div className="min-h-screen p-4">
      {/* Back button */}
      <button
        onClick={() => navigate(-1)}
        className="flex items-center text-blue-500 mb-6"
      >
        <ArrowLeft size={20} className="mr-2" />
        Back
      </button>

      {/* Invoice details card */}
      <div className="space-y-4">
        <DetailItem label="Amount" value={`${invoice.amount} SATS`} />
        <DetailItem
          label="Status"
          value={invoice.status.charAt(0).toUpperCase() +
            invoice.status.slice(1)}
        />
        {invoice.paymentHash && (
          <>
            <DetailItem
              label="Payment Hash"
              value={invoice.paymentHash}
              copyable
            />
          </>
        )}
      </div>
    </div>
  );
};

const DetailItem = ({ label, value, copyable }) => (
  <div className="flex flex-col">
    <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
      {label}
    </span>
    <div className="flex items-center">
      {typeof value === "string"
        ? (
          <>
            <span className="text-lg break-all">{value}</span>
            {copyable && (
              <button
                onClick={() => copyToClipboard(value)}
                className="ml-2 text-blue-500 hover:text-blue-600"
              >
                <Copy size={16} />
              </button>
            )}
          </>
        )
        : value}
    </div>
  </div>
);

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  // You might want to add a toast notification here
};

export default SendDetails;
