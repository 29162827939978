import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';

const Profile = () => {
  const { token } = useUser();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      fetchProfile();
    }
  }, [token, navigate]);

  const fetchProfile = async () => {
    try {
      const response = await axios.get('https://api.xbit.network/users/me/dashboard', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response.data);
      setProfile(response.data);
    } catch (err) {
      setError('Failed to fetch user profile');
      console.error(err);
    }
  };

  const handleLogout = () => {
    localStorage.setItem('isAuthenticated', 'KO');
    localStorage.removeItem('token');
    navigate('/login');
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!profile) {
    return <div className="">Loading...</div>;
  }

  return (
    <div className="min-h-screen p-2">
      <div className="mb-6">
        <div className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-t-lg p-2">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <h2 className="text-2xl mb-2">@{profile.username}</h2>
              <p>Username on X</p>
            </div>
          </div>
        </div>
        <button
          onClick={handleLogout}
          className="w-full bg-red-500 text-white py-2 text-center rounded-b-lg"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Profile;
