import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

const UserDetail = () => {
  const { username } = useParams();
  const [user, setUser] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);


  useEffect(() => {
    getUserDetail(username);
  }, []);


  const getUserDetail = async (username) => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        setAuthenticated(true);
      }
      if (authenticated) {
        const response = await axios.get(`https://api.xbit.network/users/${username}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log(response.data)
        setUser(response.data);
      } else {
        const promoterTwitterUsername = localStorage.getItem('promoterTwitterUsername');
        if (!promoterTwitterUsername) {
          localStorage.setItem('promoterTwitterUsername', username);
        }
        // const response = await axios.get(`https://api.xbit.network/public/users/${username}`);
        // setUser(response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      {authenticated ? (
        <>
          <h1 className="text-4xl font-bold mb-4">User Detail</h1>
          <p>Viewing details for user ID: {username}</p>
          {user && (
            <div>
              <p>ID: {user.id}</p>
              <p>Twitter Username: {user.twitterUsername}</p>
              <p>Sats Balance: {user.satsBalance}</p>
              <Link to="/login">login</Link>
            </div>
          )}
        </>
      ) : (
        <>
          <h1 className="text-4xl font-bold mb-4">User Detail: {username}</h1>
          <p>You are not authenticated. Please <Link to="/login">login</Link> to view user details.</p>
        </>
      )}
    </div>
  );
};

export default UserDetail;