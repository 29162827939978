import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div>
      <h1 className="text-4xl font-bold mb-4">Welcome to Our App</h1>
      <p className="mb-4">This is the homepage for unauthenticated users.</p>
      <Link to="/login" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Login
      </Link>
    </div>
  );
};

export default Home;