import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowDownUp } from "lucide-react";
import { useUser } from "../UserContext";

const formatNumber = (num) => {
  if (num === undefined) return "0";
  const parsedNum = typeof num === "string" ? parseFloat(num) : num;
  return parsedNum.toLocaleString("en-US");
};

const formatPrice = (supply, realSats) => {
  if (supply === 0) return "0";
  const price = (realSats * 1_000_000) / supply;
  return price.toLocaleString("en-US", { maximumFractionDigits: 2 });
};

const Dashboard = () => {
  const { token } = useUser();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [term, setTerm] = useState("");

  const [fromCurrency, setFromCurrency] = useState("SATS");
  const [toCurrency, setToCurrency] = useState("USD");
  const [fromAmount, setFromAmount] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [fromBalance, setFromBalance] = useState(0);
  const [toBalance, setToBalance] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [newAssets, setNewAssets] = useState([]);
  const [cardJackAssets, setCardJackAssets] = useState([]);
  const [cardQueenAssets, setCardQueenAssets] = useState([]);
  const [cardKingAssets, setCardKingAssets] = useState([]);
  const [cardAceAssets, setCardAceAssets] = useState([]);

  const [activeTab, setActiveTab] = useState("New");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      fetchDashboardData();
    }
  }, [token, navigate]);

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get(
        "https://api.xbit.network/me/dashboard",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      setData(response.data);
      const newExchangeRate = 1 / response.data.satsPrice;
      setExchangeRate(newExchangeRate);
      setNewAssets(response.data.newAssets);
      setCardJackAssets(response.data.cardJackAssets);
      setCardQueenAssets(response.data.cardQueenAssets);
      setCardKingAssets(response.data.cardKingAssets);
      setCardAceAssets(response.data.cardAceAssets);
    } catch (err) {
      setError("Failed to fetch dashboard data");
      console.error(err);
    }
  };

  const calculateAmount = (amount, from, to) => {
    const numAmount = parseFloat(amount);
    if (isNaN(numAmount)) return "";
    if (from === "SATS" && to === "USD") {
      return ((numAmount / exchangeRate) * 0.97).toFixed(2);
    } else if (from === "USD" && to === "SATS") {
      return Math.round(numAmount * exchangeRate * 0.97).toString();
    }
    return amount;
  };

  const handleBuild = async (tweetId) => {
    try {
      const response = await axios.post(
        `https://api.xbit.network/tweets/${tweetId}/build`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
    } catch (err) {
      console.error("Failed to build tweet", err);
    }
  };

  const handleNuke = async (tweetId) => {
    try {
      const response = await axios.post(
        `https://api.xbit.network/tweets/${tweetId}/nuke`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
    } catch (err) {
      console.error("Failed to nuke tweet", err);
    }
  };

  const handleFromAmountChange = (value) => {
    setFromAmount(value);
    setToAmount(calculateAmount(value, fromCurrency, toCurrency));
  };

  const handleToAmountChange = (value) => {
    setToAmount(value);
    setFromAmount(calculateAmount(value, toCurrency, fromCurrency));
  };

  const handleSwitchCurrencies = () => {
    setFromCurrency(toCurrency);
    setToCurrency(fromCurrency);
    setFromAmount("0");
    setToAmount("0");

    const newFromBalance =
      toCurrency === "USD"
        ? (data?.usdBalance?.amount ?? 0) * 100
        : data?.satsBalance ?? 0;
    const newToBalance =
      fromCurrency === "USD"
        ? (data?.usdBalance?.amount ?? 0) * 100
        : data?.satsBalance ?? 0;

    setFromBalance(newFromBalance);
    setToBalance(newToBalance);
  };

  const handleSwap = () => {
    console.log(
      `Swapping ${fromAmount} ${fromCurrency} to ${toAmount} ${toCurrency}`
    );
    // Implement the swap logic here
  };

  const handleSearch = async () => {
    console.log("Searching for:", term);
    const twitterRegex = /\/status\/(\d+)(?:\?|$)/;
    const match = term.match(twitterRegex);

    if (match) {
      const tweetId = match[1];
      console.log(`tweetId: ${tweetId}`);
      navigate(`/tweets/${tweetId}`);
    } else {
      const response = await axios.get(
        `https://api.xbit.network/tweets/search?query=${term}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      navigate("/search-results", {
        state: { tweets: response.data.tweets, users: response.data.users },
      });
    }
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!data) {
    return <div className="">Loading...</div>;
  }

  return (
    <div className="min-h-screen p-2">
      {/* first segment */}
      <div className="mb-6">
        <div className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-t-lg p-2">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h2 className="text-2xl mb-2">
                {formatNumber(data?.satsBalance)}
              </h2>
              <p>SATS</p>
            </div>
            <div>
              <h2 className="text-2xl mb-2">
                {formatNumber(Math.floor(1 / data?.satsPrice))}
              </h2>
              <p>SATS per $1.00</p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 divide-x">
          <Link
            to="/receive"
            className="bg-blue-500 text-white py-2 text-center rounded-bl-lg"
          >
            Receive
          </Link>
          <Link
            to="/send"
            className="bg-blue-500 text-white py-2 text-center rounded-br-lg"
          >
            Send
          </Link>
        </div>
      </div>

      {/* second segment */}
      <div className="mb-6">
        <div className="flex">
          <input
            type="text"
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            className="flex-grow mr-2 p-2 rounded-lg border border-gray-300"
            placeholder="Search..."
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <button
            onClick={handleSearch}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          >
            GO
          </button>
        </div>
      </div>

      {/* third segment */}
      <div className="mt-6">
        <div className="flex mb-4">
          {["New", "Jack", "Queen", "King"].map((tab) => (
            <button
              key={tab}
              className={`mr-4 ${activeTab === tab ? "font-bold" : ""}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        {activeTab === "New" && (
          <>
            {newAssets &&
              newAssets.map((asset) => (
                <Link to={`/tweets/${asset.tweetId}`} key={asset.id}>
                  <div className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-lg p-4 mb-4">
                    {asset.brightId && (
                      <>
                        <div className="flex items-center mb-2">
                          <img
                            src={asset.brightProfileImageLink}
                            alt={asset.brightName}
                            className="w-10 h-10 rounded-full mr-3"
                          />
                          <div>
                            <h3 className="font-bold">{asset.brightName}</h3>
                            <p className="text-sm text-gray-500">
                              @{asset.brightUserPosted}
                            </p>
                          </div>
                        </div>
                        
                        <p className="mb-2 break-all">{asset.brightDescription.length > 120 ? `${asset.brightDescription.slice(0, 120)}...` : asset.brightDescription}</p>
                      </>
                    )}

                    {asset.supply > 0 && (
                      <div className="grid grid-cols-2 gap-4 mb-0">
                        <div>
                          <p>Price, microSATS</p>
                          <h2 className="text-2xl mb-2">
                            {formatPrice(asset.supply, asset.realSats)}
                          </h2>
                        </div>
                        <div>
                          <p>Supply</p>
                          <h2 className="text-2xl mb-2">
                            {formatNumber(asset.supply)}
                          </h2>
                        </div>
                      </div>
                    )}
                  </div>
                </Link>
              ))}
          </>
        )}
        {activeTab === "Jack" && <></>}
        {activeTab === "Queen" && <></>}
        {activeTab === "King" && <></>}
      </div>
    </div>
  );
};

export default Dashboard;