import React from 'react';
import { useParams } from 'react-router-dom';

const VaultDetail = () => {
  const { vaultId } = useParams();

  return (
    <div>
      <h1 className="text-4xl font-bold mb-4">Vault Detail</h1>
      <p>Viewing details for vault ID: {vaultId}</p>
    </div>
  );
};

export default VaultDetail;