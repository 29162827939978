import React from 'react';
import { Link } from 'react-router-dom';

const SwapPlace = () => {
  return (
    <div>
- list of assets
    </div>
  );
};

export default SwapPlace;