import React from 'react';
import { useParams } from 'react-router-dom';

const OrderDetail = () => {
  const { orderId } = useParams();

  return (
    <div>
      <h1 className="text-4xl font-bold mb-4">Order Detail</h1>
      <p>Viewing details for order ID: {orderId}</p>
    </div>
  );
};

export default OrderDetail;