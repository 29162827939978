import React from 'react';
import { Link } from 'react-router-dom';

const Marketplace = () => {
  return (
    <div>
- list of assets
    </div>
  );
};

export default Marketplace;