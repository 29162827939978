import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Vaults = () => {
  const [invoices, setInvoices] = useState([]);
  const [swaps, setSwaps] = useState([]);
  const [activeTab, setActiveTab] = useState('invoice');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchVaults();
  }, []);

  const fetchVaults = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api.xbit.network/users/me/vaults', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Data:', response.data);    
      let invoices = []
      invoices.concat(response.data.receivingInvoices);
      invoices.concat(response.data.sendingInvoices);
      setInvoices(invoices);      
      setSwaps(response.data.stableSwaps);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching vaults:', error);
      setError('Failed to fetch vaults. Please try again later.');
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="vaults-container">
      <div className="summary-section">
        <p>Invoices: {invoices.length}</p>
        <p>Swaps: {swaps.length}</p>
      </div>

      <div className="action-buttons">
        <button className="action-button">Send</button>
        <button className="action-button">Receive</button>
        <button className="action-button">Swap</button>
      </div>

      <div className="tab-bar">
        <button 
          className={`tab-button ${activeTab === 'invoice' ? 'active' : ''}`}
          onClick={() => setActiveTab('invoice')}
        >
          Invoices
        </button>
        <button 
          className={`tab-button ${activeTab === 'swap' ? 'active' : ''}`}
          onClick={() => setActiveTab('swap')}
        >
          Swaps
        </button>
      </div>

      <div className="card-list">
        {activeTab === 'invoice' ? (
          invoices.length > 0 ? (
            invoices.map((invoice, index) => (
              <div key={index} className="card invoice-card">
                <h3>Invoice #{invoice.id}</h3>
                <p>Amount: {invoice.amount}</p>
                <p>Status: {invoice.status}</p>
                {/* Add more invoice details as needed */}
              </div>
            ))
          ) : (
            <p>No invoices found.</p>
          )
        ) : (
          swaps.length > 0 ? (
            swaps.map((swap, index) => (
              <div key={index} className="card swap-card">
                <h3>Swap #{swap.id}</h3>
                <p>From: {swap.fromAmount} {swap.fromCurrency}</p>
                <p>To: {swap.toAmount} {swap.toCurrency}</p>
                <p>Status: {swap.status}</p>
                {/* Add more swap details as needed */}
              </div>
            ))
          ) : (
            <p>No swaps found.</p>
          )
        )}
      </div>
    </div>
  );
};

export default Vaults;