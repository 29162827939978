import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";

const Assets = () => {
  const { token } = useUser();
  const navigate = useNavigate();
  const [assets, setAssets] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      fetchAssets();
    }
  }, [token, navigate]);

  const fetchAssets = async () => {
    try {
      const response = await axios.get(
        "https://api.xbit.network/me/assets",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      setAssets(response.data.assets);
    } catch (err) {
      setError("Failed to fetch assets");
      console.error(err);
    }
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="min-h-screen p-2">
      {/* first segment */}
      <div className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-lg p-2 mb-6">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h2 className="text-2xl mb-2">{assets.length}</h2>
            <p>No. of Coins</p>
          </div>
        </div>
      </div>

      {/* second segment */}
      <div className="bg-white bg-opacity-10 border border-gray-300 shadow-lg rounded-lg p-4">
        <ul className="space-y-4">
          {assets.map((asset, index) => (
            <Link
              to={`/tweets/${asset.asset.tweetId}`}
              key={asset.id}
              className="border-b border-gray-200 pb-2"
            >
              <li key={asset.id} className="pb-2 mb-4">
                <p className="font-semibold">{index + 1}. @{asset.asset.brightUserPosted}</p>
                <p className="text-sm break-all">{asset.asset.brightDescription.length > 120 ? `${asset.asset.brightDescription.slice(0, 120)}...` : asset.asset.brightDescription}</p>
                <div className="flex justify-between mt-2">
                  <span>Supply: {asset.asset.supply}</span>
                  <span>Holding: {asset.amount}</span>
                </div>
                <div className="flex justify-between mt-2">
                  <span>
                    Supply %:{" "}
                    {((asset.amount / asset.asset.supply) * 100).toFixed(2)}
                  </span>
                  <span>
                    Holding %:{" "}
                    {((asset.amount / 1_000_000_000) * 100).toFixed(2)}
                  </span>
                </div>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Assets;
